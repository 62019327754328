<template>
  <div>
    <component :is="'b-card'">
      <!-- Form: General Info Form -->
      <validation-observer ref="simpleRules">
        <b-form class="mt-1">
          <b-row>
            <!-- Field: title -->
            <b-col cols="12" md="6" lg="6">
              <b-form-group :label="$t('Name')" label-for="name">
                <validation-provider #default="{ errors }" rules="required" name="name">
                  <b-form-input id="name" v-model="itemData.name" :state="errors.length > 0 ? false:null" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="6" lg="6">
              <b-form-group :label="$t('Order Number')" label-for="number">
                <validation-provider #default="{ errors }" name="number" rules="required">
                  <b-form-input id="number" v-model="itemData.number" :state="errors.length > 0 ? false:null" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Field: Active -->
            <b-col cols="12" md="6" lg="6">
              <b-form-group
                label=""
                label-for="contact-options"
                label-class="mb-1">
                <div class="demo-inline-spacing">
                  <b-form-checkbox
                    v-model="itemData.isActive"
                    class="custom-control-primary">
                    {{ $t('Active')}}
                  </b-form-checkbox>
                </div>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="6" lg="6">
              <b-form-group :label="$t('Source Data')" label-for="sourceDataId">
                <validation-provider #default="{ errors }" rules="required" name="Source Data">
                  <v-select
                    v-model="itemData.sourceDataId"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="sourceDataList"
                    :reduce="(val) => val.id"
                    :clearable="false"
                    :state="errors.length > 0 ? false:null"
                    label="sourceTitle"
                    input-id="sourceDataId"
                    placeholder="Select Source Data"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="6" lg="6">
              <b-form-group :label="$t('Source Code Version')" label-for="sourceCodeVersion">
                <validation-provider #default="{ errors }" rules="required" name="sourceCodeVersion">
                  <b-form-input id="sourceCodeVersion" v-model="itemData.sourceCodeVersion" :state="errors.length > 0 ? false:null" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            
          </b-row>

          <b-row class="mt-2">
            <b-col>
              <save-button 
                :onClickSaveAndClose="saveRegion.bind(this, true)"
                :onClickSave="saveRegion.bind(this, false)"
                />
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </component>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, min, max,
} from '@validations'

// Alert fade
import { heightFade } from '@core/directives/animations'
// Save Button
import SaveButton from '@/components/SaveButton.vue'

// Alert
import AlertService from '@/common/alert.service'

// Toast
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'

export default {
  components: {
    SaveButton,
    ValidationProvider,
    ValidationObserver,
  },

  directives: {
    'height-fade': heightFade,
  },

  data() {
    return {
      required,
      min,
      max,

      sourceDataList: [],
      itemData: {
        id: 0,
        name: null,
        number: null,
        mixedModeType: 1,
        sourceDataId: null,
        sourceCodeVersion: null,
        isActive: true,
      },
    }
  },

  methods: {
    fetchItem() {
      const paramId = this.$router.currentRoute.params.id;
      if (parseInt(paramId, 10) !== 0) {
        this.$store.dispatch('presetMixedModeManage/fetch', { id: paramId })
          .then(response => {
            if (response.statusCode === 200) {
              this.itemData = response.result;
            } else {
              AlertService.error(this, response.message)
            }
          })
      }
    },

    saveRegion(isClose) {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$store.dispatch('presetMixedModeManage/save', this.itemData)
            .then(response => {
              if (response.statusCode === 200) {
                this.$toast({
                  component: ToastificationSuccess,
                  position: 'top-right',
                  props: { text: response.message },
                })
                if (isClose) {
                  this.$router.go(-1)
                } else {
                  this.itemData = response.result;
                  this.$router.push({ name: 'manage-preset-modern-mixedmode-period-save', params: { id: response.result.id } });
                }
              } else {
                AlertService.error(this, response.message)
              }
            });
        }
      })
    },

    loadSourceDatas() { 
      this.$store.dispatch('sourceDataManagement/fetchSourceDatasByType', { type: 7 })
        .then(response => {
          this.sourceDataList = response.result;
        });
    },
  },
  
  created() {
    this.loadSourceDatas();
    this.fetchItem();
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
