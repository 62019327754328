var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{tag:"component"},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{staticClass:"mt-1"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Name'),"label-for":"name"}},[_c('validation-provider',{attrs:{"rules":"required","name":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false:null},model:{value:(_vm.itemData.name),callback:function ($$v) {_vm.$set(_vm.itemData, "name", $$v)},expression:"itemData.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Order Number'),"label-for":"number"}},[_c('validation-provider',{attrs:{"name":"number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"number","state":errors.length > 0 ? false:null},model:{value:(_vm.itemData.number),callback:function ($$v) {_vm.$set(_vm.itemData, "number", $$v)},expression:"itemData.number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":"","label-for":"contact-options","label-class":"mb-1"}},[_c('div',{staticClass:"demo-inline-spacing"},[_c('b-form-checkbox',{staticClass:"custom-control-primary",model:{value:(_vm.itemData.isActive),callback:function ($$v) {_vm.$set(_vm.itemData, "isActive", $$v)},expression:"itemData.isActive"}},[_vm._v(" "+_vm._s(_vm.$t('Active'))+" ")])],1)])],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Source Data'),"label-for":"sourceDataId"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Source Data"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.sourceDataList,"reduce":function (val) { return val.id; },"clearable":false,"state":errors.length > 0 ? false:null,"label":"sourceTitle","input-id":"sourceDataId","placeholder":"Select Source Data"},model:{value:(_vm.itemData.sourceDataId),callback:function ($$v) {_vm.$set(_vm.itemData, "sourceDataId", $$v)},expression:"itemData.sourceDataId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Source Code Version'),"label-for":"sourceCodeVersion"}},[_c('validation-provider',{attrs:{"rules":"required","name":"sourceCodeVersion"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"sourceCodeVersion","state":errors.length > 0 ? false:null},model:{value:(_vm.itemData.sourceCodeVersion),callback:function ($$v) {_vm.$set(_vm.itemData, "sourceCodeVersion", $$v)},expression:"itemData.sourceCodeVersion"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',{staticClass:"mt-2"},[_c('b-col',[_c('save-button',{attrs:{"onClickSaveAndClose":_vm.saveRegion.bind(this, true),"onClickSave":_vm.saveRegion.bind(this, false)}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }